var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Popover Triggers" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeTrigger) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "Popovers can be triggered (opened/closed) via any combination of "
          )
        ]),
        _c("code", [_vm._v("click, hover")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("focus")]),
        _c("span", [_vm._v(" . The default trigger is ")]),
        _c("code", [_vm._v("click")]),
        _c("span", [
          _vm._v(
            " . Or a trigger of manual can be specified, where the popover can only be opened or closed programmatically."
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-popover",
                  rawName: "v-b-popover.click",
                  value:
                    "Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.",
                  expression:
                    "'Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.'",
                  modifiers: { click: true }
                }
              ],
              attrs: { title: "On Cllick Trigger", variant: "outline-primary" }
            },
            [_vm._v(" On Cllick Trigger ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-popover",
                  rawName: "v-b-popover.focus",
                  value:
                    "Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.",
                  expression:
                    "'Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.'",
                  modifiers: { focus: true }
                }
              ],
              attrs: { title: "On Focus Trigger", variant: "outline-primary" }
            },
            [_vm._v(" On Focus Trigger ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover",
                  value:
                    "Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.",
                  expression:
                    "'Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.'",
                  modifiers: { hover: true }
                }
              ],
              attrs: { title: "On Hover Trigger", variant: "outline-primary" }
            },
            [_vm._v(" On Hover Trigger ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover.focus",
                  value:
                    "Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.",
                  expression:
                    "'Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.'",
                  modifiers: { hover: true, focus: true }
                }
              ],
              attrs: {
                title: "Hover + Focus Trigger",
                variant: "outline-primary"
              }
            },
            [_vm._v(" Hover + Focus Trigger ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }