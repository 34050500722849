var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Popover Positions" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codePosition) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Twelve options are available for positioning: ")]),
        _c("code", [
          _vm._v(
            "top, topleft, topright, right, righttop, rightbottom, bottom, bottomleft, bottomright, left, lefttop,"
          )
        ]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("leftbottom")]),
        _c("span", [
          _vm._v(
            " aligned. The default position is top. Positioning is relative to the trigger element."
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover.top",
                  value:
                    "Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.",
                  expression:
                    "'Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.'",
                  modifiers: { hover: true, top: true }
                },
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { title: "Popover on top", variant: "outline-primary" }
            },
            [_vm._v(" Popover on top ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover.right",
                  value:
                    "Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.",
                  expression:
                    "'Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.'",
                  modifiers: { hover: true, right: true }
                },
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { title: "Popover on right", variant: "outline-primary" }
            },
            [_vm._v(" Popover on right ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover.left",
                  value:
                    "Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.",
                  expression:
                    "'Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.'",
                  modifiers: { hover: true, left: true }
                },
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { title: "Popover on left", variant: "outline-primary" }
            },
            [_vm._v(" Popover on left ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover.bottom",
                  value:
                    "Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.",
                  expression:
                    "'Macaroon chocolate candy. I love carrot cake gingerbread cake lemon drops. Muffin sugar plum marzipan pie.'",
                  modifiers: { hover: true, bottom: true }
                },
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { title: "Popover on bottom", variant: "outline-primary" }
            },
            [_vm._v(" Popover on bottom ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }