var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Advanced <b-popover> usage with reactive content" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeAdvance) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("You can even make your ")]),
        _c("code", [_vm._v("<b-popover>")]),
        _c("span", [_vm._v(" content interactive.")])
      ]),
      _c(
        "div",
        { attrs: { id: "my-container" } },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              ref: "button",
              attrs: { id: "popover-reactive-1", variant: "outline-primary" }
            },
            [_vm._v(" Reactive Content Using Slots ")]
          ),
          _vm.input1Return && _vm.input2Return
            ? _c(
                "b-card",
                {
                  staticClass: "shadow-none border mb-0 mt-2",
                  attrs: { title: "Returned values:" }
                },
                [
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(" Name: "),
                    _c("strong", [_vm._v(_vm._s(_vm.input1Return))]),
                    _c("br"),
                    _vm._v(" Password: "),
                    _c("strong", [_vm._v(_vm._s(_vm.input2Return))])
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "b-popover",
            {
              ref: "popover",
              attrs: {
                target: "popover-reactive-1",
                triggers: "click",
                show: _vm.popoverShow,
                placement: "auto",
                container: "my-container"
              },
              on: {
                "update:show": function($event) {
                  _vm.popoverShow = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center"
                        },
                        [_c("span", [_vm._v("Interactive Content")])]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        state: _vm.input1state,
                        "invalid-feedback": "This field is required"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: "Your Name",
                          state: _vm.input1state,
                          size: "sm"
                        },
                        model: {
                          value: _vm.input1,
                          callback: function($$v) {
                            _vm.input1 = $$v
                          },
                          expression: "input1"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        state: _vm.input2state,
                        "invalid-feedback": "This field is required"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "password",
                          placeholder: "Your Password",
                          state: _vm.input2state,
                          size: "sm"
                        },
                        model: {
                          value: _vm.input2,
                          callback: function($$v) {
                            _vm.input2 = $$v
                          },
                          expression: "input2"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      staticClass: "mr-1",
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.onClose }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(255, 255, 255, 0.15)",
                          expression: "'rgba(255, 255, 255, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      attrs: { size: "sm", variant: "primary" },
                      on: { click: _vm.onOk }
                    },
                    [_vm._v(" Ok ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }