var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Popover Methods" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeMethod) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _vm._v(
          " This is considered a “manual” popovering of the popover. popovers with zero-length titles are never displayed. "
        )
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "popover-button-1", variant: "outline-primary" },
              on: { click: _vm.showPopover }
            },
            [_vm._v(" Show popover ")]
          ),
          _c(
            "b-popover",
            {
              attrs: {
                target: "popover-button-1",
                placement: "top",
                triggers: "click"
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [_vm._v(" Show Method Popover ")]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  "Fruitcake candy cheesecake jelly beans cake gummies. Cotton candy I love sweet roll jujubes pastry cake halvah cake."
                )
              ])
            ]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "popover-button-2", variant: "outline-primary" },
              on: { click: _vm.hidePopover }
            },
            [_vm._v(" Hide popover ")]
          ),
          _c(
            "b-popover",
            {
              attrs: {
                target: "popover-button-2",
                placement: "top",
                triggers: "hover"
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [_vm._v(" Hide Method Popover ")]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  "Fruitcake candy cheesecake jelly beans cake gummies. Cotton candy I love sweet roll jujubes pastry cake halvah cake."
                )
              ])
            ]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "popover-button-3", variant: "outline-primary" },
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [_vm._v(" Toggle popover ")]
          ),
          _c(
            "b-popover",
            {
              attrs: {
                show: _vm.show,
                target: "popover-button-3",
                placement: "top",
                triggers: "click"
              },
              on: {
                "update:show": function($event) {
                  _vm.show = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [_vm._v(" Toggle Method Popover ")]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  "Fruitcake candy cheesecake jelly beans cake gummies. Cotton candy I love sweet roll jujubes pastry cake halvah cake."
                )
              ])
            ]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                id: "popover-button-disable",
                variant: "outline-primary"
              }
            },
            [_vm._v(" Disable/Enable popover ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: _vm.disabled ? "success" : "danger" },
              on: {
                click: function($event) {
                  _vm.disabled = !_vm.disabled
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.disabled ? "Enable" : "Disable") +
                  " popover by prop "
              )
            ]
          ),
          _c(
            "b-popover",
            {
              ref: "popover",
              attrs: {
                disabled: _vm.disabled,
                target: "popover-button-disable"
              },
              on: {
                "update:disabled": function($event) {
                  _vm.disabled = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [_vm._v(" Disable/Enable Popover ")]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  "Fruitcake candy cheesecake jelly beans cake gummies. Cotton candy I love sweet roll jujubes pastry cake halvah cake."
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }