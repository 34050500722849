var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Popover Options" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeOption) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover",
                  value:
                    "Tart macaroon marzipan I love soufflé apple pie wafer. Chocolate bar jelly caramels jujubes chocolate cake gummies.",
                  expression:
                    "'Tart macaroon marzipan I love soufflé apple pie wafer. Chocolate bar jelly caramels jujubes chocolate cake gummies.'",
                  modifiers: { hover: true }
                }
              ],
              attrs: {
                title: "Title from title attribute",
                variant: "outline-primary"
              }
            },
            [_vm._v(" Title + Content ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "popover-target-1", variant: "outline-primary" }
            },
            [_vm._v(" No animation ")]
          ),
          _c(
            "b-popover",
            {
              attrs: {
                target: "popover-target-1",
                triggers: "hover",
                "no-fade": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [_vm._v(" Without Fade Animation ")]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  "Tart macaroon marzipan I love soufflé apple pie wafer. Chocolate bar jelly caramels jujubes chocolate cake gummies."
                )
              ])
            ]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "popover-target-2", variant: "outline-primary" }
            },
            [_vm._v(" Delay popover ")]
          ),
          _c(
            "b-popover",
            {
              attrs: {
                target: "popover-target-2",
                triggers: "hover",
                delay: _vm.delay
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [_vm._v(" Click Triggered ")]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  "Tart macaroon marzipan I love soufflé apple pie wafer. Chocolate bar jelly caramels jujubes chocolate cake gummies."
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }